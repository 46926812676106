.App {
  height: 100%;
  margin: 0;
}

.pcenter {
  display: 'flex' !important;
  align-items: 'center';
  justify-content: 'center';
}


.App-header {
  min-height: 97px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.tablenfc {
  min-width: 350px;
  font-size: 18px;
  padding: 5px;
}

@media (min-width: 480px) {
  .tablenfc {
    min-width: 500px;
    padding: 5px;
    font-size: 22px;
  }
}

