body {
  margin: 0;
  font-family: "FrutigerLight";
  font-size: 18px;
  color: #494949;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "FrutigerLight";
  src: local("FrutigerLight"),
   url("./fonts/Frutiger_Light.ttf") format("truetype");
  font-size: 18px;
  }